<template>
  <el-card class="box-card">
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="原始密码">
        <el-input v-model="form.old_password"></el-input>
      </el-form-item>
      <el-form-item label="修改密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即修改</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      form: {
        password: "",
        old_password: "",
      },
      url: "/Login/",
      rules: {
        old_password: [
          {
            required: true,
            message: "请填写原始密码",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请填写修改密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      let _this = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.postRequest("/index/changePwd", this.form).then((resp) => {
            if (resp.code == 200) {
              _this.$message({
                type: "success",
                message: resp.msg,
              });
              this.$store.commit("INIT_TOKEN", "");
              this.$store.commit("INIT_TITLE", "");
              setTimeout(function () {
                _this.$router.push("/login");
              }, 1500);
            } else {
              _this.$message({
                type: "error",
                message: resp.msg,
              });
            }
            _this.dialogFormVisible = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
